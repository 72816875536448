import React, { Component } from 'react';
import '../Styles/footer.scss';

export default class Footer extends Component {
  render() {
    return (
      <footer className="footer-container">
        <div className="footer-content">
          <p>&copy; 2024 Komoto Tech. All rights reserved.</p>
        </div>
      </footer>
    )
  }
}
