import React from 'react';
import '../Styles/securityConsulting.scss';

export default function SecurityConsulting() {
  return (
    <div className="securityconsulting-container">
      <div className="securityconsulting-header">
        <h1>Security Consulting</h1>
      </div>
      <div className="securityconsulting-content">
        <h2>Consulting on Your Security Needs</h2>
        <p>
          Our security consulting services help you identify and mitigate potential threats to your business. We provide comprehensive assessments and tailored solutions to protect your data and infrastructure.
        </p>
      </div>
    </div>
  );
}
