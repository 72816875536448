import React from 'react';
import '../Styles/webMobileDev.scss';
import responsiveDesignImage from '../Images/all-devices.jpeg';

export default function WebMobileDevelopment() {
  return (
    <div className="webdev-container">
      <div className="webdev-header">
        <h1>Web & Mobile Development</h1>
      </div>
      <div className="webdev-content">
        <h2>Creating Dynamic and Responsive Websites</h2>
        <p>
          Our team specializes in building dynamic and responsive websites that cater to your business needs. We ensure that your website is not only visually appealing but also performs seamlessly across all devices.
        </p>
      </div>
      <div className="webdev-section">
        <div className="webdev-text">
          <h2>Available on All Devices</h2>
          <p>
            We ensure that your website is accessible on all devices, including iPhones, iPads, and laptops. Our responsive design techniques guarantee a consistent and user-friendly experience, regardless of the device your customers use.
          </p>
        </div>
        <div className="webdev-image">
          <img src={responsiveDesignImage} alt="Responsive Design" />
        </div>
      </div>
      <div className="coming-soon-section">
        <h2>Coming Soon</h2>
        <p>
          We are excited to announce that we will soon be offering automated marketing services as part of our web development solutions. Stay tuned for more updates on how our new services can help you automate your marketing efforts and grow your business.
        </p>
      </div>
    </div>
  );
}
