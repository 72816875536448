import React, { Component } from 'react';
import '../Styles/home.scss';
import headerImage from '../Images/header.jpeg';
import clientLogo1 from '../Images/client-logo/clublogo3.png';
import clientLogo2 from '../Images/client-logo/destination-sands-logo 2.jpeg';
import DevicesIcon from '@mui/icons-material/Devices';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
import SecurityIcon from '@mui/icons-material/Security';

export default class Home extends Component {
  render() {
    return (
      <div className="home-container">
        <div className="home-header">
          <img src={headerImage} alt="Header" />
        </div>
        <div className="home-intro">
          <h1>Welcome to Komoto Tech</h1>
          <p>
            At Komoto Tech, we provide innovative technology solutions to help your business thrive in the digital age. Our team of experts is dedicated to delivering high-quality services in web and app development, IT consulting, and cyber security auditing.
          </p>
        </div>
        <div className="home-clients">
          <h2>Portfolio</h2>
          <div className="client-logos">
            <img src={clientLogo1} alt="Client 1" />
            <img src={clientLogo2} alt="Client 2" />
          </div>
        </div>
        <div className="home-services">
          <h2>Our Services</h2>
          <div className="services-list">
            <div className="service">
              <DevicesIcon style={{ fontSize: 60, color: '#007BFF' }} />
              <h3>Web and Mobile Development</h3>
              <p>We create dynamic and responsive web applications tailored to your business needs.</p>
            </div>
            <div className="service">
              <WifiPasswordIcon style={{ fontSize: 60, color: '#007BFF' }} />
              <h3>IT Support</h3>
              <p>Our IT support services help troubleshoot and repair so you leverage technology to improve your business processes.</p>
            </div>
            <div className="service">
              <SecurityIcon style={{ fontSize: 60, color: '#007BFF' }} />
              <h3>Security Consulting</h3>
              <p>Protect your business from cyber threats with our comprehensive cyber security consulting and auditing services.</p>
            </div>
          </div>
        </div>
        <div className="home-news">
          <h2>Latest News</h2>
          <div className="news-list">
            <div className="news-item">
              <h3>Komoto Tech Launches New Website</h3>
              <p>We are excited to announce the launch of our new website, designed with a fresh new look and user-friendly navigation.</p>
            </div>
            <div className="news-item">
              <h3>Cybersecurity Tips for 2024</h3>
              <p>Stay safe online with our latest cybersecurity tips and best practices for the upcoming year.</p>
            </div>
            <div className="news-item">
              <h3>New IT Consulting Services Available</h3>
              <p>We now offer comprehensive IT consulting services to help businesses leverage technology effectively.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
