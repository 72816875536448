import React from 'react';
import './Styles/App.scss';
import logo from './Images/logo.png'; 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Importing Components
import NavContainer from './Navagation/navbar';
import Footer from './Navagation/footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import WebMobileDevelopment from './pages/WebMobileDev';
import ITSupport from './pages/itsupport';
import SecurityConsulting from './pages/SecurityConsulting';

function App() {
  return (
    <Router>
      <div className="App">
        <img src={logo} alt="Komoto Tech Logo" className='App-Logo' />
        <header className='App-Header'>
          <NavContainer />
        </header>
        <main className='main-content'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/webdev' element={<WebMobileDevelopment />} />
            <Route path='/itsupport' element={<ITSupport />} />
            <Route path='/infosec' element={<SecurityConsulting />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
