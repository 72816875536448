// ITSupport.js
import React from 'react';
import '../Styles/itSupport.scss';
import supportImage from '../Images/tech-support.jpeg';
import repairImage from '../Images/motherboard.jpeg';

const ITSupport = () => {
  return (
    <div className="itsupport-container">
      <div className="itsupport-header">
        <h1>IT Support</h1>
      </div>
      <div className="itsupport-section">
        <div className="itsupport-text">
          <h2>Comprehensive IT Support</h2>
          <p>
            Our IT support services are designed to help you troubleshoot and resolve any computer hardware issues you may encounter. Whether you're experiencing performance problems, hardware malfunctions, or just need answers to your tech-related questions, we're here to help.
          </p>
        </div>
        <div className="itsupport-image">
          <img src={supportImage} alt="IT Support" />
        </div>
      </div>
      <div className="itsupport-section hardware-repair">
        <div className="itsupport-text">
          <h2>Hardware Repair Services</h2>
          <p>
            We offer professional hardware repair services to ensure your devices are running smoothly. From diagnosing hardware issues to performing necessary repairs, our team of experts is equipped to handle all your hardware needs.
          </p>
        </div>
        <div className="itsupport-image">
          <img src={repairImage} alt="Hardware Repair" />
        </div>
      </div>
    </div>
  );
}

export default ITSupport;
