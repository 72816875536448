import React, { Component } from 'react';
import '../Styles/about.scss';
import aboutImage from '../Images/about-img.jpeg';
import certLogo1 from '../Images/cert-logos/SecurityPlus Logo Certified CE.jpg';
import certLogo2 from '../Images/cert-logos/aws-certified-cloud-practitioner.png';
import certLogo3 from '../Images/cert-logos/Linux+ ce certified logo.jpg';
import certLogo4 from '../Images/cert-logos/azure-logo.png';

export default class About extends Component {
  render() {
    return (
      <div className="about-container">
        <div className="about-header">
          <h1>About Us</h1>
        </div>
        <div className="about-content">
          <div className="about-image">
            <img src={aboutImage} alt="About Us" />
          </div>
          <div className="about-text">
            <p>
              Welcome to Komoto Tech! We are a start up technology company dedicated to providing innovative solutions to our clients. Our team specializes in various fields including web development, cybersecurity, IT support, and consulting.
            </p>
            <p>
              Our mission is to deliver high-quality services that exceed our clients' expectations. We believe in the power of technology to transform businesses and improve lives. With a commitment to excellence and a passion for technology, we strive to be your trusted partner in the digital world.
            </p>
            <p>
              Whether you're looking to develop a new website, secure your device, repair and troubleshoot computer or hardware issues, Komoto Tech is here to help. Get in touch with us today to learn more about our services and how we can assist you.
            </p>
          </div>
        </div>
        <div className="about-certifications">
          <h2>Our Certifications</h2>
          <div className="cert-logos">
            <img src={certLogo1} alt="Certification 1" />
            <img src={certLogo2} alt="Certification 2" />
            <img src={certLogo3} alt="Certification 3" />
            <img src={certLogo4} alt="Certification 4" />
          </div>
        </div>
      </div>
    );
  }
}
