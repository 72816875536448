import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function NavContainer() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className='nav-wrapper'>
      <nav>
        <NavLink to='/' activeClassName="active">Home</NavLink>
        <NavLink to='/about' activeClassName="active">About</NavLink>
        <div 
          className="dropdown" 
          onMouseEnter={() => setDropdownOpen(true)} 
          onMouseLeave={() => setDropdownOpen(false)}
          onClick={toggleDropdown}
        >
          <span>Services</span>
          {dropdownOpen && (
            <div className="dropdown-content">
              <NavLink to='/webdev'>Web & Mobile Development</NavLink>
              <NavLink to='/itsupport'>IT Support</NavLink>
              <NavLink to='/infosec'>Security Consulting</NavLink>
            </div>
          )}
        </div>
        <NavLink to='/contact' activeClassName="active">Contact</NavLink>
      </nav>
    </div>
  );
}
