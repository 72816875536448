import React, { Component } from 'react';
import '../Styles/contact.scss';

export default class Contact extends Component {
  render() {
    return (
      <div className="contact-container">
        <div className="contact-header">
          <h1>Contact Us</h1>
        </div>
        <div className="contact-content">
          <form className="contact-form">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" required />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />

            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" rows="4" required></textarea>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    );
  }
}
